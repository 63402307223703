import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { init as SentryInit, Replay } from '@sentry/capacitor'
import {
  browserTracingIntegration,
  // replayIntegration,
  captureConsoleIntegration,
  init as SentryReactInit,
} from '@sentry/react'
SentryInit(
  {
    enabled: import.meta.env.PROD,
    dsn: 'https://72d5c0de4cc1cf759be1cb28a8d76c26@o4507429601935360.ingest.us.sentry.io/4507429604884480',
    integrations: [
      browserTracingIntegration({
        tracePropagationTargets: ['localhost'],
      }),
      // TODO: replay integration was causing app-bound domains js error
      // see how to fix
      // replayIntegration({
      //   maskAllInputs: true,
      //   maskAllText: false,
      //   blockAllMedia: false,
      //   networkDetailAllowUrls: ['https://api.privee.network/v1'],
      //   // networkRequestHeaders: ['Cookie', 'X-Fallback-Cookies'],
      // }),
      captureConsoleIntegration({
        levels: ['log', 'warn'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  // Forward the init method from @sentry/react
  SentryReactInit,
)
// import LogRocket from 'logrocket'
// LogRocket.init('vscyvv/privee')
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
